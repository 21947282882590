import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { decode } from '@vl/mod-utils/jwt';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const getUUid = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            form: hook(() => {
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  name: '',
                  avatar_url: '',
                  class_time_rule: {
                    before_start: 1,
                    before_end: 15,
                  },
                  support_materials: [],
                  acl_lesson_materials: [],
                  program_setting: true,
                  approval: [],
                  evaluation: [],
                  grading_system: '',
                },

                onSubmit: async (values, actions) => {
                  try {
                    console.log(values);
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                handleChangeCheckbox: (e) => {
                  const { checked, name } = e.target;
                  if (checked) {
                    form.setFieldValue('support_materials', [...form.values.support_material, { id: name }]);
                  } else {
                    form.setFieldValue(
                      'support_material',
                      _.filter(_.get(form, `values.support_materials`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                selectOptions: {
                  file_format: (() => {
                    return [
                      {
                        id: 1,
                        text: '*.doc',
                      },
                      {
                        id: 2,
                        text: '*.docx',
                      },
                      {
                        id: 3,
                        text: '*.pdf',
                      },
                      {
                        id: 4,
                        text: '*.pptx',
                      },
                      {
                        id: 5,
                        text: '*.mp3',
                      },
                      {
                        id: 6,
                        text: '*.jpeg',
                      },
                      {
                        id: 7,
                        text: '*.png',
                      },
                      {
                        id: 8,
                        text: '*.jpg',
                      },
                      {
                        id: 9,
                        text: '*.other',
                      },
                    ];
                  })(),
                  material_acl: (() => {
                    return [
                      {
                        id: 'material_1',
                        text: 'Up to current lesson only',
                        children: [{ id: 'material_c1_1', text: 'Allow teacher to manually publish lesson' }],
                      },
                      {
                        id: 'material_2',
                        text: 'Only 1 lesson in advance',
                        children: [{ id: 'material_c2_1', text: 'Allow teacher to manually publish lesson' }],
                      },
                      {
                        id: 'material_3',
                        text: 'All lessons',
                        children: [],
                      },
                    ];
                  })(),
                  program: (() => {
                    return [
                      {
                        id: 'program_1',
                        value: true,
                        text: 'Enabled',
                      },
                      {
                        id: 'program_2',
                        value: false,
                        text: 'Disabled',
                      },
                    ];
                  })(),
                  approval_acl: (() => {
                    return [
                      {
                        id: 'approval_1',
                        text: 'Automatically approve class cancellation lesson requests',
                      },
                      {
                        id: 'approval_2',
                        text: 'Automatically approve reschedule lesson requests',
                      },
                    ];
                  })(),
                  evaluation: (() => {
                    return [
                      {
                        id: 'evaluation_1',
                        text: 'Student Concentration during the Lesson',
                      },
                      {
                        id: 'evaluation_2',
                        text: 'Student Activeness during the Lesson',
                      },
                      {
                        id: 'evaluation_3',
                        text: 'Student Classification',
                      },
                    ];
                  })(),
                  grading_system: (() => {
                    return [
                      {
                        id: 'grading_system_1',
                        text: 'By German Grading System',
                      },
                      {
                        id: 'grading_system_2',
                        text: 'By US Grading System',
                      },
                      {
                        id: 'grading_system_3',
                        text: 'By UK Grading System',
                      },
                    ];
                  })(),
                },
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.apply('loadingModel.isLoading');
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
