import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import Image from '@uz/unitz-components-web/Image';
import { Upload, Progress, Row, Col } from 'antd';
import InputText from '@uz/unitz-components-web/InputText';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import Button from '@uz/unitz-components-web/Button';
import { Form, FormItem } from 'formik-antd';
import ToolClassTimeRuleSetting from '@uz/unitz-tool-components/ToolClassTimeRuleSetting';
import ToolSupportedMaterialsSetting from '@uz/unitz-tool-components/ToolSupportedMaterialsSetting';
import ToolAccessListMaterialsSetting from '@uz/unitz-tool-components/ToolAccessListMaterialsSetting';
import ToolProgramSetting from '@uz/unitz-tool-components/ToolProgramSetting';
import ToolCheckboxSetting from '@uz/unitz-tool-components/ToolCheckboxSetting';
import ToolSelectSetting from '@uz/unitz-tool-components/ToolSelectSetting';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const layouts = {
  labelCol: { span: 4 },
  labelAlign: 'left',
};
const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 h-full">
          <DIV className="isLoading">
            <div className="fixed flex justify-center items-center w-full h-full top-0 left-0 select-none bg-white200">
              <LoadingScreen />
            </div>
          </DIV>
          <div className="mb-4 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolSettings.title')}
          </div>
          <DIV className="notLoading">
            <FormProvider form={ctx.get('form')}>
              <Form {...layouts} className="settings-form">
                <div className="rounded-lg shadow-md p-6 bg-white500 mb-4">
                  <FormItem
                    name="name"
                    label={
                      <span className="text-left text-sm font-semibold text-sub">
                        {ctx.apply('i18n.t', 'ZoomToolSettings.organization_name')}
                      </span>
                    }
                  >
                    <CustomInput>
                      <InputText name="name" placeholder={'Vicat'} />
                    </CustomInput>
                  </FormItem>

                  <FormItem
                    name="avatar_url"
                    label={
                      <span className="text-sm font-semibold text-sub">
                        {ctx.apply('i18n.t', 'ZoomToolSettings.company_logo')}
                      </span>
                    }
                  >
                    <CustomInput>
                      <div className="relative">
                        <CoverPhotoInput
                          {...ctx.apply('form.getUploaderProps', { fieldName: 'avatar_url', multiple: false })}
                          itemRender={() => null}
                        >
                          {!ctx.get('form.values.avatar_url.0') ? (
                            <div className="flex space-y-2 flex-col items-center justify-center py-2">
                              <div className="text-center ant-upload-text">
                                {gstyles.icons({ name: 'add-photo', size: 28, fill: gstyles.colors.sub })}
                              </div>
                              <Button style={{ borderColor: gstyles.colors.border }} name="ink" type="outline">
                                Add photo
                              </Button>
                              <div className="text-sm font-normal ant-upload-hint text-sub">Drop a photo here</div>
                            </div>
                          ) : (
                            <div className="flex items-center justify-center">
                              <Image
                                alt="photo"
                                src={ctx.get('form.values.avatar_url.0.url')}
                                width={320}
                                height={140}
                              />
                            </div>
                          )}
                        </CoverPhotoInput>
                        {ctx.get('form.values.avatar_url.0') && (
                          <div className="absolute top-0 right-0">
                            <Button
                              type="link"
                              name="ink"
                              onClick={() => ctx.apply('form.setFieldValue', 'avatar_url', [])}
                            >
                              <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                            </Button>
                          </div>
                        )}
                        {ctx.get('form.values.avatar_url.0.status') === 'uploading' && (
                          <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                            <Progress width={80} type="circle" percent={ctx.get('form.values.avatar_url.0.percent')} />
                          </div>
                        )}
                      </div>
                    </CustomInput>
                  </FormItem>
                  <div className="flex flex-col space-y-2">
                    <ToolClassTimeRuleSetting />
                    <ToolSupportedMaterialsSetting />
                    <ToolAccessListMaterialsSetting />
                    <ToolProgramSetting />
                    <ToolCheckboxSetting
                      name="approval"
                      label={ctx.apply('i18n.t', 'ZoomToolSettings.approval.label')}
                      data={ctx.get('form.selectOptions.approval_acl')}
                    />
                    <ToolCheckboxSetting
                      name="evaluation"
                      label={ctx.apply('i18n.t', 'ZoomToolSettings.evaluation.label')}
                      data={ctx.get('form.selectOptions.evaluation')}
                    />
                  </div>
                </div>
                <ToolSelectSetting
                  name="grading_system"
                  title={ctx.apply('i18n.t', 'ZoomToolSettings.grading_system.title')}
                  note={ctx.apply('i18n.t', 'ZoomToolSettings.grading_system.note')}
                  label={ctx.apply('i18n.t', 'ZoomToolSettings.grading_system.label')}
                  data={ctx.get('form.selectOptions.grading_system')}
                />
                <div className="mt-4 p-6 rounded-lg bg-white500 shadow-lg">
                  <Row justify="end">
                    <Col span={4}>
                      <Button
                        size="medium"
                        type="primary"
                        loading={ctx.get('form.isSubmitting')}
                        onClick={() => ctx.apply('form.submitForm')}
                        disabled={!ctx.get('canSubmit')}
                        block
                      >
                        {ctx.apply('i18n.t', 'Setting.Language.save')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </FormProvider>
          </DIV>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
