import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { FormItem, InputNumber } from 'formik-antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';

export const ToolClassTimeRuleSetting = () => {
  return (
    <DIV>
      <CustomInput>
        <FormItem
          name="class_time_rule"
          label={
            <div className="text-sm font-semibold text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.class_time_rules.label')}
            </div>
          }
        >
          <div className="flex items-end mb-4 space-x-2">
            <div className="text-sm font-normal text-main">
              {ctx.apply('i18n.t', 'ZoomToolSettings.class_time_rules.tardy')}
            </div>
            <InputNumber name="class_time_rule.before_start" />
            <div className="text-sm font-normal text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.class_time_rules.minutes')}
            </div>
          </div>
          <div className="flex items-end mb-4 space-x-2">
            <div className="text-sm font-normal text-main">
              {ctx.apply('i18n.t', 'ZoomToolSettings.class_time_rules.early')}
            </div>
            <InputNumber name="class_time_rule.before_end" />
            <div className="text-sm font-normal text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.class_time_rules.minutes')}
            </div>
          </div>
        </FormItem>
      </CustomInput>
    </DIV>
  );
};

export default ToolClassTimeRuleSetting;
