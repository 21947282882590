import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Space } from 'antd';
import { FormItem, Radio } from 'formik-antd';
import CustomRadio from '@uz/unitz-components-web/CustomRadio';

export const ToolProgramSetting = () => {
  return (
    <DIV>
      <CustomRadio>
        <FormItem
          name="program_setting"
          label={
            <div className="text-sm font-semibold text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.program.label')}
            </div>
          }
        >
          <Radio.Group name="program_setting">
            <Space direction="horizontal">
              {_.map(ctx.get('form.selectOptions.program'), (item, index) => (
                <>
                  <Radio key={index} value={item.id}>
                    <span className="text-sub font-semibold">{item.text}</span>
                  </Radio>
                </>
              ))}
            </Space>
          </Radio.Group>
        </FormItem>
      </CustomRadio>
    </DIV>
  );
};

export default ToolProgramSetting;
