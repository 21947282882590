import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { FormItem, Checkbox } from 'formik-antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import cx from 'classnames';

export const ToolCheckboxSetting = ({ name, data, label }) => {
  return (
    <DIV>
      <CustomInput>
        <FormItem name={name} label={<div className={'text-sm font-semibold text-sub whitespace-normal'}>{label}</div>}>
          <Row>
            {_.map(data, (item, index) => (
              <Col key={index} className="mb-3" span={24}>
                <Checkbox
                  // checked={_.map(ctx.get('form.values.support_material'), (e) => e.id).includes(item.id)}
                  name={`item_${item.id}`}
                  // onChange={ctx.get('form.handleChangeCheckbox')}
                >
                  {item.text}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </FormItem>
      </CustomInput>
    </DIV>
  );
};

export default ToolCheckboxSetting;
