import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Space } from 'antd';
import { FormItem, Radio, Checkbox } from 'formik-antd';
import CustomRadio from '@uz/unitz-components-web/CustomRadio';
import cx from 'classnames';

export const ToolAccessListMaterialsSetting = () => {
  return (
    <DIV>
      <CustomRadio>
        <FormItem
          name="material_acl"
          label={
            <div className="text-sm whitespace-normal font-semibold text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.lessons_materials.label')}
            </div>
          }
        >
          <Radio.Group name="material_acl">
            <Space direction="vertical">
              {_.map(ctx.get('form.selectOptions.material_acl'), (item, index) => (
                <>
                  <Radio
                    className={cx('', {
                      'mb-3': !_.get(item, 'children.length'),
                    })}
                    key={index}
                    value={item.id}
                  >
                    {item.text}
                  </Radio>
                  {!!_.get(item, 'children.length') && (
                    <div className="py-2 ml-6">
                      {_.map(_.get(item, 'children', []), (child, index) => (
                        <Checkbox
                          key={index}
                          // checked={_.map(ctx.get('form.values.support_material'), (e) => e.id).includes(item.id)}
                          name={`option_${child.id}`}
                        >
                          {child.text}
                        </Checkbox>
                      ))}
                    </div>
                  )}
                </>
              ))}
            </Space>
          </Radio.Group>
        </FormItem>
      </CustomRadio>
    </DIV>
  );
};

export default ToolAccessListMaterialsSetting;
