import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { FormItem, Checkbox } from 'formik-antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';

export const ToolSupportedMaterialsSetting = () => {
  return (
    <DIV>
      <CustomInput>
        <FormItem
          name="support_materials"
          label={
            <div className="text-sm font-semibold text-sub">
              {ctx.apply('i18n.t', 'ZoomToolSettings.supported_materials')}
            </div>
          }
        >
          <Row>
            {/* {_.map(ctx.get('roleOptions'), (role, index) => (
              <Col key={index} className="mb-6" span={12}>
                <Checkbox
                  checked={_.map(ctx.get('form.values.roles'), (e) => e.id).includes(role.role_id)}
                  name={role.role_id}
                  onChange={ctx.get('form.handleChangeCheckbox')}
                >
                  {role.name}
                </Checkbox>
              </Col>
            ))} */}
            {_.map(ctx.get('form.selectOptions.file_format'), (item, index) => (
              <Col key={index} className="mb-6" span={8}>
                <Checkbox
                  // checked={_.map(ctx.get('form.values.support_material'), (e) => e.id).includes(item.id)}
                  name={`file_item_${item.id}`}
                  // onChange={ctx.get('form.handleChangeCheckbox')}
                >
                  {item.text}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </FormItem>
      </CustomInput>
    </DIV>
  );
};

export default ToolSupportedMaterialsSetting;
